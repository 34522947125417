<div id="dialog-container-create-investment">
  <h1 mat-dialog-title>{{ 'TITLE.ADD_INVESTMENT' | translate }}</h1>

  <mat-divider></mat-divider>

  <mat-dialog-content class="dialog-content-no-stepper fx-flex-auto">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'LABEL.NAME' | translate }}</mat-label>
      <input matInput [id]="'create_investment_provider'" [formControl]="nameForm" required/>
      @if (nameForm.hasError('required')) {
        <mat-error>
          {{ 'ERROR.FIELD_REQUIRED' | translate }}
        </mat-error>
      }
      @if (nameForm.hasError('maxlength')) {
        <mat-error>
          {{ 'ERROR.FIELD_MAX_LENGTH' | translate: {value: appConfig.FIELD_MAX_LENGTH} }}
        </mat-error>
      }
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button matDialogClose="cancel" [id]="'cancel-button'">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button [disabled]="!nameForm.valid"
            [id]="'add-button'"
            color="accent"
            mat-raised-button
            matDialogClose="yes">
      {{ 'BUTTON.ADD' | translate }}
    </button>
  </mat-dialog-actions>
</div>
