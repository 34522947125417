import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { gql, WatchQueryFetchPolicy } from '@apollo/client/core';
import { Investment, InvestmentInput } from '@app/core/model/entities/investment/investment';
import { ActivationEndService } from '@app/features/main/activation-end.service';
import { UsersService } from '@app/shared/services/users.service';
import { GeneralService } from '@services/general.service';
import { AppManager } from '@services/managers/app.manager';
import { plainToInstance } from 'class-transformer';
import { from, Observable, Subject } from 'rxjs';
import { map, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class InvestmentsService {

  private sidePanelToggle = new Subject<Investment | null>();
  private investmentAdded = new Subject<Investment>();
  private investmentUpdated = new Subject<Investment>();
  private investmentsDeleted = new Subject<Investment[]>();

  private readonly investmentInfoGraphqlFragment = gql`
    fragment InvestmentInfo on Investment {
      id
      name
      identifier
      type
      entityId
      organizationId
      properties
      computedProperties
      creationUserId
      creationDate
      lastChangeUserId
      lastChangeDate
      dataDate
    }
  `;

  private appManager = inject(AppManager);
  private activationEndService = inject(ActivationEndService);
  private generalService = inject(GeneralService);
  private usersService = inject(UsersService);
  private router = inject(Router);

  /**
   * Emits data for the side panel to display or null whenever the side panel is closed.
   */
  public get sidePanelToggle$(): Observable<Investment | null> {
    return this.sidePanelToggle.asObservable();
  }

  /**
   * Emits the new Investment after it was created.
   * @returns Observable emitting the new Investment.
   */
  public get investmentAdded$(): Observable<Investment> {
    return this.investmentAdded.asObservable();
  }

  /**
   * Emits the updated Investment after it was updated.
   * @returns Observable emitting the updated Investment.
   */
  public get investmentUpdated$(): Observable<Investment> {
    return this.investmentUpdated.asObservable();
  }

  /**
   * Emits the deleted Investment after it was deleted.
   * @returns Observable emitting the deleted Investment.
   */
  public get investmentsDeleted$(): Observable<Investment[]> {
    return this.investmentsDeleted.asObservable();
  }

  /**
   * Fetch the Investment based on the ID contained in the activated route's path.
   * Call automatically by the route Resolver.
   * @param route Activated route.
   * @return Observable emitting the Investment.
   */
  public resolve(route: ActivatedRouteSnapshot): Observable<Investment> {
    return this.loadInvestment(route.paramMap.get('id'), 'cache-first').pipe(
      // Fetch Users who created and last updated the Investment
      switchMap(investment => this.usersService.fetchUsersInfo(investment)),
      tap(() => this.activationEndService.getRefreshHeaderSubject().next())
    );
  }

  /**
   * Make an API request to fetch an Investment.
   * @param id ID of the Investment.
   * @param fetchPolicy GraphQL query fetch policy, default is 'network-only'.
   * @return Observable emitting a list of all accessible Investments.
   */
  public loadInvestment(id: string, fetchPolicy: WatchQueryFetchPolicy = 'network-only'): Observable<Investment> {
    const QUERY = gql`
      query Investment($id: String!) {
        investment(id: $id) {
          ...InvestmentInfo
        }
      } ${this.investmentInfoGraphqlFragment}
    `;
    const QUERY_VAR = {id};

    return this.generalService.get(QUERY, QUERY_VAR, fetchPolicy).pipe(
      map(response => plainToInstance(Investment, response.data['investment'] as Investment))
    );
  }

  /**
   * Make an API request to fetch Investments linked to the current Organization and accessible by the current user.
   * @return Observable emitting a list of all accessible Investments.
   */
  public loadInvestments(): Observable<Investment[]> {
    const QUERY = gql`
      query Investments($organizationId: String!) {
        investments(organizationId: $organizationId) {
          ...InvestmentInfo
        }
      } ${this.investmentInfoGraphqlFragment}
    `;
    const QUERY_VAR = {
      organizationId: this.appManager.currentOrganization.id
    };

    return this.generalService.get(QUERY, QUERY_VAR).pipe(
      map(response => plainToInstance(Investment, response.data['investments'] as Investment[])),
      // Fetch Users who created and last updated the Investment
      switchMap(investments => from(investments)),
      mergeMap(investment => this.usersService.fetchUsersInfo(investment)),
      toArray()
    );
  }

  /**
   * Fetch Investments related to a specific Asset.
   * @param assetId The ID of the Asset whose Investments should be fetched.
   * @returns An Observable emitting a list of Investments linked to the specified Asset.
   */
  public loadAssetInvestments(assetId: string): Observable<Investment[]> {
    const QUERY = gql`
      query Investments($assetId: String!) {
        investmentsByAsset(assetId: $assetId) {
          ...InvestmentInfo
        }
      } ${this.investmentInfoGraphqlFragment}
    `;
    const QUERY_VAR = {assetId};

    return this.generalService.get(QUERY, QUERY_VAR).pipe(
      map(response => plainToInstance(Investment, response.data['investmentsByAsset'] as Investment[])),
      // Fetch Users who created and last updated the Investment
      switchMap(investments => from(investments)),
      mergeMap(investment => this.usersService.fetchUsersInfo(investment)),
      toArray()
    );
  }

  /**
   * Fetch Investments related to a specific Work.
   * @param workId The ID of the Work whose Investments should be fetched.
   * @returns An Observable emitting a list of Investments linked to the specified Work.
   */
  public loadWorkInvestments(workId: string): Observable<Investment[]> {
    const QUERY = gql`
      query Investments($workId: String!) {
        investmentsByWork(workId: $workId) {
          ...InvestmentInfo
        }
      } ${this.investmentInfoGraphqlFragment}
    `;
    const QUERY_VAR = {workId};

    return this.generalService.get(QUERY, QUERY_VAR).pipe(
      map(response => plainToInstance(Investment, response.data['investmentsByWork'] as Investment[])),
      // Fetch Users who created and last updated the Investment
      switchMap(investments => from(investments)),
      mergeMap(investment => this.usersService.fetchUsersInfo(investment)),
      toArray()
    );
  }

  /**
   * Fetch Investments related to a specific Equipment.
   * @param equipmentId The ID of the Equipment whose Investments should be fetched.
   * @returns An Observable emitting a list of Investments linked to the specified Equipment.
   */
  public loadEquipmentInvestments(equipmentId: string): Observable<Investment[]> {
    const QUERY = gql`
      query Investments($equipmentId: String!) {
        investmentsByEquipment(equipmentId: $equipmentId) {
          ...InvestmentInfo
        }
      } ${this.investmentInfoGraphqlFragment}
    `;
    const QUERY_VAR = {equipmentId};

    return this.generalService.get(QUERY, QUERY_VAR).pipe(
      map(response => plainToInstance(Investment, response.data['investmentsByEquipment'] as Investment[])),
      // Fetch Users who created and last updated the Investment
      switchMap(investments => from(investments)),
      mergeMap(investment => this.usersService.fetchUsersInfo(investment)),
      toArray()
    );
  }

  /**
   * Fetch Investments related to a specific Project.
   * @param projectId The ID of the Project whose Investments should be fetched.
   * @returns An Observable emitting a list of Investments linked to the specified Project.
   */
  public loadProjectInvestments(projectId: string): Observable<Investment[]> {
    const QUERY = gql`
      query Investments($projectId: String!) {
        investmentsByProject(projectId: $projectId) {
          ...InvestmentInfo
        }
      } ${this.investmentInfoGraphqlFragment}
    `;
    const QUERY_VAR = {projectId};

    return this.generalService.get(QUERY, QUERY_VAR).pipe(
      map(response => plainToInstance(Investment, response.data['investmentsByProject'] as Investment[])),
      // Fetch Users who created and last updated the Investment
      switchMap(investments => from(investments)),
      mergeMap(investment => this.usersService.fetchUsersInfo(investment)),
      toArray()
    );
  }

  /**
   * Create a new Investment with the provided data.
   * @param investmentInput Investment data.
   * @returns An Observable emitting the new Investment that was created.
   */
  public createInvestment(investmentInput: InvestmentInput): Observable<Investment> {
    const MUTATION = gql`
      mutation CreateInvestment($organizationId: String!, $investmentInput: InvestmentInput!) {
        createInvestment(organizationId: $organizationId, investmentInput: $investmentInput) {
          ...InvestmentInfo
        }
      } ${this.investmentInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {
      organizationId: this.appManager.currentOrganization.id,
      investmentInput: investmentInput
    };

    return this.generalService.set(MUTATION, MUTATION_VAR).pipe(
      map(response => plainToInstance(Investment, response.data['createInvestment'] as Investment)),
      switchMap(investment => this.usersService.fetchUsersInfo(investment)),
      tap(investment => this.investmentAdded.next(investment))
    );
  }

  /**
   * Call the API to update an Investment.
   * @param investment Investment to update.
   * @param investmentInput Data to update the Investment with.
   * @returns An Observable emitting the updated Investment once the update is completed.
   */
  public updateInvestment(investment: Investment, investmentInput: InvestmentInput): Observable<Investment> {
    const MUTATION = gql`
      mutation UpdateInvestment($id: String!, $investmentInput: InvestmentInput!) {
        updateInvestment(id: $id, investmentInput: $investmentInput) {
          ...InvestmentInfo
        }
      }
      ${this.investmentInfoGraphqlFragment}
    `;
    const MUTATION_VAR = {
      id: investment.id,
      investmentInput: {
        name: investment.name,
        entityId: investment.entityId,
        type: investment.type,
        ...investmentInput
      }
    };

    return this.generalService.set(MUTATION, MUTATION_VAR).pipe(
      map(response => plainToInstance(Investment, response.data['updateInvestment'])),
      switchMap(investment => this.usersService.fetchUsersInfo(investment)),
      tap(investment => this.investmentUpdated.next(investment))
    );
  }

  /**
   * Call the API to delete Investments.
   * @param investments Investments to delete.
   * @returns An Observable emitting true if all Investments have been deleted successfully, false otherwise.
   */
  public deleteInvestments(investments: Investment[]): Observable<boolean> {
    const MUTATION = gql`
      mutation DeleteInvestment($ids: [String!]!) {
        deleteInvestments(ids: $ids)
      }
    `;
    const MUTATION_VAR = {ids: investments.map(investment => investment.id)};

    return this.generalService.set(MUTATION, MUTATION_VAR).pipe(
      map(response => response.data['deleteInvestments'] as boolean),
      tap(success => success && this.investmentsDeleted.next(investments))
    );
  }

  /**
   * Open the Investment side panel.
   * @param investment Investment to display in the side panel.
   */
  public openInvestmentSidePanel(investment: Investment): void {
    this.sidePanelToggle.next(investment);
  }

  /**
   * Close the Investment side panel.
   */
  public closeInvestmentSidePanel(): void {
    this.sidePanelToggle.next(null);
  }

  /**
   * Navigate to the Investment sheet.
   * @param id ID of the Investment to navigate to.
   */
  public async navigateToInvestmentSheet(id: string): Promise<void> {
    await this.router.navigate([
      'organization',
      this.appManager.currentOrganization.id,
      'investments',
      'investment-sheet',
      id
    ]);
  }
}
