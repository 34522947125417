import { IReportEmbedConfiguration } from 'embed';
import { models } from 'powerbi-client';
import { Observable } from 'rxjs';

export interface RefreshState {
  name: string;
  lastRefreshTime: string,
  status: RefreshStatus;
}

export declare type RefreshStatus = 'Unknown' | 'InProgress' | 'Failed' | 'Success' | 'Completed';
export declare type ReportType = 'PowerBIReport' | 'PaginatedReport';
export declare type ReportName =
  'assetReport'
  | 'organizationReport'
  | 'workReport'
  | 'workPaginatedReport'
  | 'assetWorkPaginatedReport'
  | 'equipmentReport'
  | 'equipmentPaginatedReport'
  | 'assetEquipmentPaginatedReport'
  | 'spaceReport'
  | 'projectReport'
  | 'investmentReport'
  | 'analysisReport'
  ;
export declare type ReportPageName =
  'assetReportDefaultPage'
  | 'organizationReportDefaultPage'
  | 'workReportDefaultPage'
  | 'equipmentReportDefaultPage'
  | 'spaceReportDefaultPage'
  | 'projectReportDefaultPage'
  | 'investmentReportDefaultPage'
  | 'analysisReportDefaultPage';
export declare type ReportDatasetName =
  'organizationDataset'
  | 'assetDataset'
  | 'equipmentDataset'
  | 'workDataset'
  | 'spaceDataset'
  | 'projectDataset'
  | 'investmentDataset'
  | 'analysisDataset';

export class PowerBIReportConfig implements IReportEmbedConfiguration {
  public type = 'report';
  public tokenType = models.TokenType.Embed;
  public filters = [];
  public settings = {
    panes: {
      filters: {
        expanded: false,
        visible: false
      },
      pageNavigation: {
        visible: false
      },
      background: models.BackgroundType.Transparent
    }
  };

  /**
   * Build an embed powerBI filter config
   * @param table table on which the filter will be applied
   * @param column property to filter on.
   * @param value value to match.
   * @returns An embed powerBI filter config
   */
  public static buildFilter(table: string, column: string, value: string): any {
    return {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table,
        column
      },
      operator: 'In',
      values: [value],
      filterType: models.FilterType.Basic,
      requireSingleSelection: true
    };
  }
}

export interface PowerBIReport {
  id: string;
  type: ReportType;
  name: string;
}

export interface PowerBIReportInput {
  label: string;
  reportFormControlName: ReportName;
  defaultPageFormControlName: ReportPageName;
  pages$: Observable<PageBIReport[]>;
  datasetName: ReportDatasetName;
  dataset: RefreshState;
}

export interface PageBIReport {
  id: string;
  name: string;
}

export interface Dataflow {
  id: string;
  name: string;
}

export function getPowerBIReportInputs(): PowerBIReportInput[] {
  return <PowerBIReportInput[]>[
    {
      label: 'LABEL.ANALYSIS_BI_REPORT',
      reportFormControlName: 'analysisReport',
      defaultPageFormControlName: 'analysisReportDefaultPage',
      datasetName: 'analysisDataset'
    },
    {
      label: 'LABEL.ORGANIZATION_BI_REPORT',
      reportFormControlName: 'organizationReport',
      defaultPageFormControlName: 'organizationReportDefaultPage',
      datasetName: 'organizationDataset'
    },
    {
      label: 'LABEL.ASSET_BI_REPORT',
      reportFormControlName: 'assetReport',
      defaultPageFormControlName: 'assetReportDefaultPage',
      datasetName: 'assetDataset'
    },
    {
      label: 'LABEL.SPACE_BI_REPORT',
      reportFormControlName: 'spaceReport',
      defaultPageFormControlName: 'spaceReportDefaultPage',
      datasetName: 'spaceDataset'
    },
    {
      label: 'LABEL.EQUIPMENT_BI_REPORT',
      reportFormControlName: 'equipmentReport',
      defaultPageFormControlName: 'equipmentReportDefaultPage',
      datasetName: 'equipmentDataset'
    },
    {
      label: 'LABEL.WORK_BI_REPORT',
      reportFormControlName: 'workReport',
      defaultPageFormControlName: 'workReportDefaultPage',
      datasetName: 'workDataset'
    },
    {
      label: 'LABEL.PROJECT_BI_REPORT',
      reportFormControlName: 'projectReport',
      defaultPageFormControlName: 'projectReportDefaultPage',
      datasetName: 'projectDataset'
    },
    {
      label: 'LABEL.INVESTMENT_BI_REPORT',
      reportFormControlName: 'investmentReport',
      defaultPageFormControlName: 'investmentReportDefaultPage',
      datasetName: 'investmentDataset'
    }
  ];
}
