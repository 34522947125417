import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AppConfig } from '@app/core/app.config';
import { AnalyticsKeyEnum } from '@app/core/enums/analytics/analytics-key.enum';
import { ActionEnum } from '@app/core/enums/analytics/analytics-value.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { PermissionEnum } from '@app/core/enums/permissions.enum';
import { Report } from '@app/core/model/client/report';
import { AnalyticsEvent } from '@app/core/model/entities/analytics/analytics-event';
import { Organization } from '@app/core/model/entities/organization/organization';
import { OrganizationLogoService } from '@app/features/main/views/dashboard/organization-logo.service';
import { MyReportService } from '@app/features/main/views/my-pages/my-report/my-report.service';
import { OrganizationsService } from '@app/shared/services/organizations.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessManager } from '@services/managers/access.manager';
import { AppManager } from '@services/managers/app.manager';
import { SnackbarManager } from '@services/managers/snackbar.manager';
import { plainToInstance } from 'class-transformer';
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { EMPTY, merge, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, delayWhen, filter, map, mergeWith, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'my-report',
  templateUrl: './my-report.component.html',
  styleUrls: ['./my-report.component.scss'],
  providers: [MyReportService]
})
export class MyReportComponent implements OnInit, OnDestroy {

  public userReport: Report = null;
  public sendDate$: Observable<Dayjs>;

  public Permission = PermissionEnum;
  public Organization = Organization;

  // Days of week : from 1 to 7
  public daysOfWeek: number[] = Array.from(Array(7), (x, i) => i + 1);
  // Hours of day : from 0 to 23
  public hours: number[] = Array.from(Array(24), (x, i) => i);
  // One, two or 4 weeks
  public periodicityWeekList: number[] = [7, 14, 28];
  // Periodicity for assetChecks, lease and contract renew dates : from 1 to 11
  public periodicityMonthList: number[] = Array.from(Array(11), (x, i) => i + 1);
  public timeUnits = [{'days': 7, 'unit': 'WEEKS'}, {'days': 30, 'unit': 'MONTHS'}];

  public reportForm: UntypedFormGroup;
  public organizationForm: FormControl<Organization>;

  public organizations$: Observable<Organization[]>;
  private destroy$ = new Subject<void>();

  constructor(private fb: UntypedFormBuilder,
              private appManager: AppManager,
              public appConfig: AppConfig,
              public translate: TranslateService,
              private analyticsService: AnalyticsService,
              public accessManager: AccessManager,
              private snackbarManager: SnackbarManager,
              private organizationsService: OrganizationsService,
              private reportService: MyReportService,
              private organizationLogoService: OrganizationLogoService) {

    dayjs.extend(isoWeek);
    dayjs.extend(utc);
    dayjs.extend(timezone);
  }

  /**
   * Calculate the next report date. If a previous report exists, the next date will be calculated from it.
   * @param day The week day to send the report
   * @param hour The hour of day to send the report
   * @param frequency The interval in days between each report.
   * @return The next report date.
   */
  private calculateNextSendDate(day: number, hour: number, frequency: number): Dayjs {
    if (this.userReport.lastSendDate.isValid()) {
      let daysToAdd = day  - this.userReport.lastSendDate.day();
      if (daysToAdd < 0) daysToAdd += 7;

      const nextDate = this.userReport.lastSendDate
        .add(daysToAdd, 'day')
        .add(frequency, 'day');
      if (nextDate.isAfter(dayjs())) return nextDate;
    }

    if (day < dayjs().day() || (day === dayjs().day() && hour <= dayjs().hour())) {
      return dayjs().day(day + 7);
    }

    return dayjs().day(day);
  }

  public ngOnInit(): void {
    // Load organization list
    this.organizations$ = this.organizationsService.loadData().pipe(takeUntil(this.destroy$));

    // Initialize forms
    this.organizationForm = this.fb.control(this.appManager.currentOrganization);

    this.reportForm = this.fb.group({
      activateReports: this.fb.control(false),
      dayOfWeek: this.fb.control(''),
      hour: this.fb.control(''),
      periodicity: this.fb.control(''),
      noReportDataError: this.fb.control(false),
      // FIXME Add to entitiesHistory formGroup
      checkToRenewNumber: this.fb.control(''),
      checkToRenewPeriodicity: this.fb.control(''),
      checkPeriodicityError: this.fb.control(false),
      leaseToRenewNumber: this.fb.control(''),
      leaseToRenewPeriodicity: this.fb.control(''),
      leasePeriodicityError: this.fb.control(false),
      contractToRenewNumber: this.fb.control(''),
      contractToRenewPeriodicity: this.fb.control(''),
      contractPeriodicityError: this.fb.control(false),
      entitiesHistory: this.fb.group({
        onAssetCreated: this.fb.control(false),
        onAssetUpdated: this.fb.control(false),
        onAssetDeleted: this.fb.control(false),
        onSpaceCreated: this.fb.control(false),
        onSpaceUpdated: this.fb.control(false),
        onSpaceDeleted: this.fb.control(false),
        onDocumentCreated: this.fb.control(false),
        onDocumentUpdated: this.fb.control(false),
        onDocumentDeleted: this.fb.control(false),
        onCheckCreated: this.fb.control(false),
        onCheckUpdated: this.fb.control(false),
        onCheckDeleted: this.fb.control(false),
        onCheckExpired: this.fb.control(false),
        checkRenewalEnabled: this.fb.control(false),
        onOccupantCreated: this.fb.control(false),
        onOccupantUpdated: this.fb.control(false),
        onOccupantDeleted: this.fb.control(false),
        onLeaseCreated: this.fb.control(false),
        onLeaseUpdated: this.fb.control(false),
        onLeaseDeleted: this.fb.control(false),
        onLeaseExpired: this.fb.control(false),
        leaseRenewalEnabled: this.fb.control(false),
        onContractCreated: this.fb.control(false),
        onContractUpdated: this.fb.control(false),
        onContractDeleted: this.fb.control(false),
        onContractExpired: this.fb.control(false),
        contractRenewalEnabled: this.fb.control(false),
        onWorkCreated: this.fb.control(false),
        onWorkUpdated: this.fb.control(false),
        onWorkDeleted: this.fb.control(false),
        onEquipmentCreated: this.fb.control(false),
        onEquipmentUpdated: this.fb.control(false),
        onEquipmentDeleted: this.fb.control(false),
        onProjectCreated: this.fb.control(false),
        onProjectUpdated: this.fb.control(false),
        onProjectDeleted: this.fb.control(false),
        onInvestmentCreated: this.fb.control(false),
        onInvestmentUpdated: this.fb.control(false),
        onInvestmentDeleted: this.fb.control(false)
      })
    });

    this.reportForm.get('activateReports').valueChanges
      .subscribe(activateReport => {
        if (activateReport) {
          this.reportForm.get('dayOfWeek').setValidators([Validators.required]);
          this.reportForm.get('hour').setValidators([Validators.required]);
          this.reportForm.get('periodicity').setValidators([Validators.required]);
        } else {
          this.reportForm.get('dayOfWeek').setValidators([]);
          this.reportForm.get('hour').setValidators([]);
          this.reportForm.get('periodicity').setValidators([]);
        }
      });

    this.sendDate$ = merge(
      this.reportForm.get('dayOfWeek').valueChanges,
      this.reportForm.get('hour').valueChanges,
      this.reportForm.get('periodicity').valueChanges
    )
      .pipe(
        startWith(''),
        debounceTime(50),
        takeUntil(this.destroy$),
        map(() => {
          return {
            selectedDay: this.reportForm.get('dayOfWeek').value,
            selectedHour: this.reportForm.get('hour').value,
            selectedPeriodicity: this.reportForm.get('periodicity').value
          };
        }),
        filter(({selectedDay, selectedHour, selectedPeriodicity}) => {
          return selectedDay !== '' && selectedHour !== '' && selectedPeriodicity;
        }),
        map(({selectedDay, selectedHour, selectedPeriodicity}) => {
          return this.calculateNextSendDate(selectedDay, selectedHour, selectedPeriodicity);
        })
      );

    merge(
      this.reportForm.get('activateReports').valueChanges,
      this.reportForm.get('entitiesHistory').valueChanges
    )
      .pipe(
        startWith(this.reportForm.get('activateReports').value),
        debounceTime(50),
        takeUntil(this.destroy$)
      )
      // FIXME Add to entitiesHistory formGroup : exclude non boolean value
      .subscribe(() => {
        if (!this.reportForm.get('activateReports').value) {
          this.reportForm.get('noReportDataError').setValue(false);
        } else {
          this.reportForm.get('noReportDataError').setValue(
            !Object.values(this.reportForm.get('entitiesHistory').value).some(isActivated => isActivated)
          );
        }
      });

    merge(
      this.reportForm.get('checkToRenewNumber').valueChanges,
      this.reportForm.get('checkToRenewPeriodicity').valueChanges
    )
      .pipe(debounceTime(50), takeUntil(this.destroy$))
      .subscribe(() => {

        if (this.reportForm.get('entitiesHistory').get('checkRenewalEnabled').value) {
          const checkRenewalInterval = (this.reportForm.get('checkToRenewNumber').value && this.reportForm.get(
            'checkToRenewPeriodicity').value)
            ? this.reportForm.get('checkToRenewNumber').value * this.reportForm.get('checkToRenewPeriodicity').value
            : 0;

          if (checkRenewalInterval < this.reportForm.get('periodicity').value) {
            this.reportForm.get('checkPeriodicityError').setValue(true);
          } else {
            this.reportForm.get('checkPeriodicityError').setValue(false);
          }
        }
      });

    merge(
      this.reportForm.get('leaseToRenewNumber').valueChanges,
      this.reportForm.get('leaseToRenewPeriodicity').valueChanges
    )
      .pipe(debounceTime(50), takeUntil(this.destroy$))
      .subscribe(() => {

        if (this.reportForm.get('entitiesHistory').get('leaseRenewalEnabled').value) {
          const leaseRenewalInterval = (this.reportForm.get('leaseToRenewNumber').value && this.reportForm.get(
            'leaseToRenewPeriodicity').value)
            ? this.reportForm.get('leaseToRenewNumber').value * this.reportForm.get('leaseToRenewPeriodicity').value
            : 0;

          if (leaseRenewalInterval < this.reportForm.get('periodicity').value) {
            this.reportForm.get('leasePeriodicityError').setValue(true);
          } else {
            this.reportForm.get('leasePeriodicityError').setValue(false);
          }
        }
      });

    merge(
      this.reportForm.get('contractToRenewNumber').valueChanges,
      this.reportForm.get('contractToRenewPeriodicity').valueChanges
    )
      .pipe(debounceTime(50), takeUntil(this.destroy$))
      .subscribe(() => {

        if (this.reportForm.get('entitiesHistory').get('contractRenewalEnabled').value) {
          const contractRenewalInterval = (this.reportForm.get('contractToRenewNumber').value && this.reportForm.get(
            'contractToRenewPeriodicity').value)
            ? this.reportForm.get('contractToRenewNumber').value * this.reportForm.get('contractToRenewPeriodicity').value
            : 0;

          if (contractRenewalInterval < this.reportForm.get('periodicity').value) {
            this.reportForm.get('contractPeriodicityError').setValue(true);
          } else {
            this.reportForm.get('contractPeriodicityError').setValue(false);
          }
        }
      });

    this.reportForm.get('entitiesHistory').get('checkRenewalEnabled').valueChanges.pipe(
      debounceTime(50),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.reportForm.get('checkToRenewNumber').updateValueAndValidity();
    });

    this.reportForm.get('entitiesHistory').get('leaseRenewalEnabled').valueChanges.pipe(
      debounceTime(50),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.reportForm.get('leaseToRenewNumber').updateValueAndValidity();
    });

    this.reportForm.get('entitiesHistory').get('contractRenewalEnabled').valueChanges.pipe(
      debounceTime(50),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.reportForm.get('contractToRenewNumber').updateValueAndValidity();
    });

    this.reportForm.get('periodicity').valueChanges.pipe(debounceTime(50), takeUntil(this.destroy$)).subscribe(() => {
      this.reportForm.get('checkToRenewNumber').updateValueAndValidity();
      this.reportForm.get('leaseToRenewNumber').updateValueAndValidity();
      this.reportForm.get('contractToRenewNumber').updateValueAndValidity();
    });

    // Load and get report for the selected organization
    this.organizationForm.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith(this.organizationForm.value),
      filter(organization => !!organization),
      // Required to load the user permissions whenever we switch to another organization.
      delayWhen(organization => this.organizationsService.loadOrganization(organization.id)),
      // Load organization report or create it if it does not exist
      switchMap(organization => {
        return this.reportService.doesUserReportExist(organization.id).pipe(
          switchMap(exist => {
            if (exist) return this.reportService.getReportByOrganizationId(organization.id);
            else return this.reportService.createReport(organization.id, dayjs.tz.guess());
          }),
          // Load organization logo
          mergeWith(this.organizationLogoService.loadOrganizationLogo().pipe(switchMap(() => EMPTY)))
        );
      })
    )
      .subscribe(report => {
        // Set report and reset form
        this.userReport = report;
        this.resetForm();
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public updateUserReport(): void {
    const checkRenewalInterval = (this.reportForm.get('entitiesHistory').get('checkRenewalEnabled').value)
      ? (this.reportForm.get('checkToRenewNumber').value && this.reportForm.get('checkToRenewPeriodicity').value)
        ? this.reportForm.get('checkToRenewNumber').value * this.reportForm.get('checkToRenewPeriodicity').value
        : 0
      : 0;

    const leaseRenewalInterval = (this.reportForm.get('entitiesHistory').get('leaseRenewalEnabled').value)
      ? (this.reportForm.get('leaseToRenewNumber').value && this.reportForm.get('leaseToRenewPeriodicity').value)
        ? this.reportForm.get('leaseToRenewNumber').value * this.reportForm.get('leaseToRenewPeriodicity').value
        : 0
      : 0;

    const contractRenewalInterval = (this.reportForm.get('entitiesHistory').get('contractRenewalEnabled').value)
      ? (this.reportForm.get('contractToRenewNumber').value && this.reportForm.get('contractToRenewPeriodicity').value)
        ? this.reportForm.get('contractToRenewNumber').value * this.reportForm.get('contractToRenewPeriodicity').value
        : 0
      : 0;

    const MUTATION_VAR = {
      reportId: this.userReport.id,
      organizationId: this.organizationForm.value.id,
      reportInput: {
        enabled: this.reportForm.get('activateReports').value,

        weekday: this.reportForm.get('dayOfWeek').value,
        timeOfDay: this.reportForm.get('hour').value,
        frequency: this.reportForm.get('periodicity').value,
        timezoneId: dayjs.tz.guess(),

        assetCreatedEnabled: this.reportForm.get('entitiesHistory').get('onAssetCreated').value,
        assetUpdatedEnabled: this.reportForm.get('entitiesHistory').get('onAssetUpdated').value,
        assetDeletedEnabled: this.reportForm.get('entitiesHistory').get('onAssetDeleted').value,

        spaceCreatedEnabled: this.reportForm.get('entitiesHistory').get('onSpaceCreated').value,
        spaceUpdatedEnabled: this.reportForm.get('entitiesHistory').get('onSpaceUpdated').value,
        spaceDeletedEnabled: this.reportForm.get('entitiesHistory').get('onSpaceDeleted').value,

        checkCreatedEnabled: this.reportForm.get('entitiesHistory').get('onCheckCreated').value,
        checkUpdatedEnabled: this.reportForm.get('entitiesHistory').get('onCheckUpdated').value,
        checkDeletedEnabled: this.reportForm.get('entitiesHistory').get('onCheckDeleted').value,
        checkExpiredEnabled: this.reportForm.get('entitiesHistory').get('onCheckExpired').value,
        checkRenewalEnabled: this.reportForm.get('entitiesHistory').get('checkRenewalEnabled').value,
        checkRenewalInterval: checkRenewalInterval,

        occupantCreatedEnabled: this.reportForm.get('entitiesHistory').get('onOccupantCreated').value,
        occupantUpdatedEnabled: this.reportForm.get('entitiesHistory').get('onOccupantUpdated').value,
        occupantDeletedEnabled: this.reportForm.get('entitiesHistory').get('onOccupantDeleted').value,

        leaseCreatedEnabled: this.reportForm.get('entitiesHistory').get('onLeaseCreated').value,
        leaseUpdatedEnabled: this.reportForm.get('entitiesHistory').get('onLeaseUpdated').value,
        leaseDeletedEnabled: this.reportForm.get('entitiesHistory').get('onLeaseDeleted').value,
        leaseExpiredEnabled: this.reportForm.get('entitiesHistory').get('onLeaseExpired').value,
        leaseRenewalEnabled: this.reportForm.get('entitiesHistory').get('leaseRenewalEnabled').value,
        leaseRenewalInterval: leaseRenewalInterval,

        contractCreatedEnabled: this.reportForm.get('entitiesHistory').get('onContractCreated').value,
        contractUpdatedEnabled: this.reportForm.get('entitiesHistory').get('onContractUpdated').value,
        contractDeletedEnabled: this.reportForm.get('entitiesHistory').get('onContractDeleted').value,
        contractExpiredEnabled: this.reportForm.get('entitiesHistory').get('onContractExpired').value,
        contractRenewalEnabled: this.reportForm.get('entitiesHistory').get('contractRenewalEnabled').value,
        contractRenewalInterval: contractRenewalInterval,

        workCreatedEnabled: this.reportForm.get('entitiesHistory').get('onWorkCreated').value,
        workUpdatedEnabled: this.reportForm.get('entitiesHistory').get('onWorkUpdated').value,
        workDeletedEnabled: this.reportForm.get('entitiesHistory').get('onWorkDeleted').value,

        projectCreatedEnabled: this.reportForm.get('entitiesHistory').get('onProjectCreated').value,
        projectUpdatedEnabled: this.reportForm.get('entitiesHistory').get('onProjectUpdated').value,
        projectDeletedEnabled: this.reportForm.get('entitiesHistory').get('onProjectDeleted').value,

        equipmentCreatedEnabled: this.reportForm.get('entitiesHistory').get('onEquipmentCreated').value,
        equipmentUpdatedEnabled: this.reportForm.get('entitiesHistory').get('onEquipmentUpdated').value,
        equipmentDeletedEnabled: this.reportForm.get('entitiesHistory').get('onEquipmentDeleted').value,

        investmentCreatedEnabled: this.reportForm.get('entitiesHistory').get('onInvestmentCreated').value,
        investmentUpdatedEnabled: this.reportForm.get('entitiesHistory').get('onInvestmentUpdated').value,
        investmentDeletedEnabled: this.reportForm.get('entitiesHistory').get('onInvestmentDeleted').value,

        documentCreatedEnabled: this.reportForm.get('entitiesHistory').get('onDocumentCreated').value,
        documentUpdatedEnabled: this.reportForm.get('entitiesHistory').get('onDocumentUpdated').value,
        documentDeletedEnabled: this.reportForm.get('entitiesHistory').get('onDocumentDeleted').value
      }
    };
    const analyticsEvent = new AnalyticsEvent(ActionEnum.UPDATE, EntityTypeEnum.USER_REPORT);
    analyticsEvent.addProperties({[AnalyticsKeyEnum.ENTITY_ID]: this.userReport.id.toString()});
    this.reportService.updateUserReport(MUTATION_VAR).pipe(takeUntil(this.destroy$)).subscribe({
      next: (response) => {
        this.userReport = plainToInstance(Report, response.data['updateUserReport'] as Report);
        analyticsEvent.addProperties({[AnalyticsKeyEnum.CURRENT_PAGE]: ActionEnum.SAVE});
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.EDIT_SAVED'));
      },
      error: () => {
        analyticsEvent.addProperties({[AnalyticsKeyEnum.CURRENT_PAGE]: ActionEnum.CANCEL});
      },
      complete: () => {
        this.analyticsService.trackEvent(analyticsEvent);
      }
    });
  }

  public resetForm(): void {
    this.reportForm.get('activateReports').setValue(this.userReport.enabled);
    this.reportForm.get('dayOfWeek').setValue(this.userReport.weekday);
    this.reportForm.get('hour').setValue(this.userReport.timeOfDay);
    this.reportForm.get('periodicity').setValue(this.userReport.frequency);

    if (this.userReport.assetConfig) {
      this.reportForm.get('entitiesHistory').get('onAssetCreated').setValue(!!this.userReport.assetConfig.createdEnabled);
      this.reportForm.get('entitiesHistory').get('onAssetUpdated').setValue(!!this.userReport.assetConfig.updatedEnabled);
      this.reportForm.get('entitiesHistory').get('onAssetDeleted').setValue(!!this.userReport.assetConfig.deletedEnabled);
    }

    if (this.userReport.spaceConfig) {
      this.reportForm.get('entitiesHistory').get('onSpaceCreated').setValue(!!this.userReport.spaceConfig.createdEnabled);
      this.reportForm.get('entitiesHistory').get('onSpaceUpdated').setValue(!!this.userReport.spaceConfig.updatedEnabled);
      this.reportForm.get('entitiesHistory').get('onSpaceDeleted').setValue(!!this.userReport.spaceConfig.deletedEnabled);
    }

    if (this.userReport.documentConfig) {
      this.reportForm.get('entitiesHistory').get('onDocumentCreated').setValue(!!this.userReport.documentConfig.createdEnabled);
      this.reportForm.get('entitiesHistory').get('onDocumentUpdated').setValue(!!this.userReport.documentConfig.updatedEnabled);
      this.reportForm.get('entitiesHistory').get('onDocumentDeleted').setValue(!!this.userReport.documentConfig.deletedEnabled);
    }

    if (this.userReport.checkConfig) {
      this.reportForm.get('entitiesHistory').get('onCheckCreated').setValue(!!this.userReport.checkConfig.createdEnabled);
      this.reportForm.get('entitiesHistory').get('onCheckUpdated').setValue(!!this.userReport.checkConfig.updatedEnabled);
      this.reportForm.get('entitiesHistory').get('onCheckDeleted').setValue(!!this.userReport.checkConfig.deletedEnabled);
      this.reportForm.get('entitiesHistory').get('onCheckExpired').setValue(!!this.userReport.checkConfig.expiredEnabled);
      if (this.userReport.checkConfig.renewal) {
        this.reportForm.get('entitiesHistory').get('checkRenewalEnabled').setValue(this.userReport.checkConfig.renewal.renewalEnabled);
        const interval = this.userReport.checkConfig.renewal.interval;
        if (Math.trunc(interval / 7) <= 11) {
          this.reportForm.get('checkToRenewNumber').setValue(Math.trunc(interval / 7));
          this.reportForm.get('checkToRenewPeriodicity').setValue(7);
        } else {
          this.reportForm.get('checkToRenewNumber').setValue(Math.trunc(interval / 30));
          this.reportForm.get('checkToRenewPeriodicity').setValue(30);
        }
      }
    }

    if (this.userReport.occupantConfig) {
      this.reportForm.get('entitiesHistory').get('onOccupantCreated').setValue(!!this.userReport.occupantConfig.createdEnabled);
      this.reportForm.get('entitiesHistory').get('onOccupantUpdated').setValue(!!this.userReport.occupantConfig.updatedEnabled);
      this.reportForm.get('entitiesHistory').get('onOccupantDeleted').setValue(!!this.userReport.occupantConfig.deletedEnabled);
    }

    if (this.userReport.leaseConfig) {
      this.reportForm.get('entitiesHistory').get('onLeaseCreated').setValue(!!this.userReport.leaseConfig.createdEnabled);
      this.reportForm.get('entitiesHistory').get('onLeaseUpdated').setValue(!!this.userReport.leaseConfig.updatedEnabled);
      this.reportForm.get('entitiesHistory').get('onLeaseDeleted').setValue(!!this.userReport.leaseConfig.deletedEnabled);
      this.reportForm.get('entitiesHistory').get('onLeaseExpired').setValue(!!this.userReport.leaseConfig.expiredEnabled);
      if (this.userReport.leaseConfig.renewal) {
        this.reportForm.get('entitiesHistory').get('leaseRenewalEnabled').setValue(this.userReport.leaseConfig.renewal.renewalEnabled);
        const interval = this.userReport.leaseConfig.renewal.interval;
        if (Math.trunc(interval / 7) <= 11) {
          this.reportForm.get('leaseToRenewNumber').setValue(Math.trunc(interval / 7));
          this.reportForm.get('leaseToRenewPeriodicity').setValue(7);
        } else {
          this.reportForm.get('leaseToRenewNumber').setValue(Math.trunc(interval / 30));
          this.reportForm.get('leaseToRenewPeriodicity').setValue(30);
        }
      }
    }

    if (this.userReport.contractConfig) {
      this.reportForm.get('entitiesHistory').get('onContractCreated').setValue(!!this.userReport.contractConfig.createdEnabled);
      this.reportForm.get('entitiesHistory').get('onContractUpdated').setValue(!!this.userReport.contractConfig.updatedEnabled);
      this.reportForm.get('entitiesHistory').get('onContractDeleted').setValue(!!this.userReport.contractConfig.deletedEnabled);
      this.reportForm.get('entitiesHistory').get('onContractExpired').setValue(!!this.userReport.contractConfig.expiredEnabled);
      if (this.userReport.contractConfig.renewal) {
        this.reportForm.get('entitiesHistory').get('contractRenewalEnabled').setValue(this.userReport.contractConfig.renewal.renewalEnabled);
        const interval = this.userReport.contractConfig.renewal.interval;
        if (Math.trunc(interval / 7) <= 11) {
          this.reportForm.get('contractToRenewNumber').setValue(Math.trunc(interval / 7));
          this.reportForm.get('contractToRenewPeriodicity').setValue(7);
        } else {
          this.reportForm.get('contractToRenewNumber').setValue(Math.trunc(interval / 30));
          this.reportForm.get('contractToRenewPeriodicity').setValue(30);
        }
      }
    }

    if (this.userReport.workConfig) {
      this.reportForm.get('entitiesHistory').get('onWorkCreated').setValue(!!this.userReport.workConfig.createdEnabled);
      this.reportForm.get('entitiesHistory').get('onWorkUpdated').setValue(!!this.userReport.workConfig.updatedEnabled);
      this.reportForm.get('entitiesHistory').get('onWorkDeleted').setValue(!!this.userReport.workConfig.deletedEnabled);
    }

    if (this.userReport.equipmentConfig) {
      this.reportForm.get('entitiesHistory').get('onEquipmentCreated').setValue(!!this.userReport.equipmentConfig.createdEnabled);
      this.reportForm.get('entitiesHistory').get('onEquipmentUpdated').setValue(!!this.userReport.equipmentConfig.updatedEnabled);
      this.reportForm.get('entitiesHistory').get('onEquipmentDeleted').setValue(!!this.userReport.equipmentConfig.deletedEnabled);
    }

    if (this.userReport.projectConfig) {
      this.reportForm.get('entitiesHistory').get('onProjectCreated').setValue(!!this.userReport.projectConfig.createdEnabled);
      this.reportForm.get('entitiesHistory').get('onProjectUpdated').setValue(!!this.userReport.projectConfig.updatedEnabled);
      this.reportForm.get('entitiesHistory').get('onProjectDeleted').setValue(!!this.userReport.projectConfig.deletedEnabled);
    }

    if (this.userReport.investmentConfig) {
      this.reportForm.get('entitiesHistory').get('onInvestmentCreated').setValue(!!this.userReport.investmentConfig.createdEnabled);
      this.reportForm.get('entitiesHistory').get('onInvestmentUpdated').setValue(!!this.userReport.investmentConfig.updatedEnabled);
      this.reportForm.get('entitiesHistory').get('onInvestmentDeleted').setValue(!!this.userReport.investmentConfig.deletedEnabled);
    }
  }

  public sendLastReport(): Subscription {
    const analyticsEvent = new AnalyticsEvent(ActionEnum.SEND, EntityTypeEnum.USER_REPORT);
    analyticsEvent.addProperties({[AnalyticsKeyEnum.ENTITY_ID]: this.userReport.id.toString()});
    return this.reportService.sendLastReport(this.organizationForm.value.id).subscribe((response) => {
      if (response.data['sendLastUserReport']) {
        analyticsEvent.addProperties({[AnalyticsKeyEnum.CURRENT_PAGE]: ActionEnum.SAVE});
        this.snackbarManager.showActionSnackbar(this.translate.instant('SUCCESS.REPORT_SENT'));
      } else {
        analyticsEvent.addProperties({[AnalyticsKeyEnum.CURRENT_PAGE]: ActionEnum.CANCEL});
        this.snackbarManager.showActionSnackbar(this.translate.instant('ERROR.SENDING_REPORT'));
      }
      this.analyticsService.trackEvent(analyticsEvent);
    });
  }

  public onButtonResetClick(): void {
    this.analyticsService.trackEvent(new AnalyticsEvent(ActionEnum.UPDATE, EntityTypeEnum.USER_REPORT)
      .addProperties({[AnalyticsKeyEnum.CURRENT_PAGE]: ActionEnum.CANCEL}));
    this.resetForm();
  }

  public formatDate(dateToFormat): string {
    if (dateToFormat) {
      return dayjs(dateToFormat).format(this.appConfig.DISPLAY_DATE_FORMAT);
    } else {
      return null;
    }
  }
}
