import { Injectable } from '@angular/core';
import { Report } from '@app/core/model/client/report';
import { GeneralService } from '@services/general.service';
import { gql } from 'apollo-angular';
import { plainToInstance } from 'class-transformer';
import { map, Observable } from 'rxjs';

@Injectable()
export class MyReportService {

  private readonly reportGraphqlFragment: any;

  constructor(private generalService: GeneralService) {
    this.reportGraphqlFragment = gql`
      fragment ReportConfig on UserReport {
        id
        organizationId
        enabled
        lastSendDate
        weekday
        timeOfDay
        frequency
        assetConfig {
          createdEnabled
          updatedEnabled
          deletedEnabled
        }
        spaceConfig {
          createdEnabled
          updatedEnabled
          deletedEnabled
        }
        checkConfig {
          createdEnabled
          updatedEnabled
          deletedEnabled
          expiredEnabled
          renewal {
            renewalEnabled
            interval
          }
        }
        occupantConfig {
          createdEnabled
          updatedEnabled
          deletedEnabled
        }
        leaseConfig {
          createdEnabled
          updatedEnabled
          deletedEnabled
          expiredEnabled

          renewal {
            renewalEnabled
            interval
          }
        }
        contractConfig {
          createdEnabled
          updatedEnabled
          deletedEnabled
          expiredEnabled

          renewal {
            renewalEnabled
            interval
          }
        }
        workConfig {
          createdEnabled
          updatedEnabled
          deletedEnabled
        }
        equipmentConfig {
          createdEnabled
          updatedEnabled
          deletedEnabled
        }
        projectConfig {
          createdEnabled
          updatedEnabled
          deletedEnabled
        }
        investmentConfig {
          createdEnabled
          updatedEnabled
          deletedEnabled
        }
        documentConfig {
          createdEnabled
          updatedEnabled
          deletedEnabled
        }
      }
    `;
  }

  /**
   * Check if the current user report exists for the organization.
   * @param organizationId The ID of the organization.
   * @return Whether the report exists.
   */
  public doesUserReportExist(organizationId: string): Observable<boolean> {
    const QUERY = gql`
      query DoesUserReportExist($organizationId: String!) {
        doesUserReportExist(organizationId: $organizationId)
      }
    `;
    const QUERY_VAR = {organizationId};

    return this.generalService.get(QUERY, QUERY_VAR).pipe(
      map(response => response.data['doesUserReportExist'] as boolean)
    );
  }

  /**
   * Get user report by organization id
   * @param organizationId
   * @returns The User report
   */
  public getReportByOrganizationId(organizationId: string): Observable<Report> {
    const QUERY = gql`
      query UserReport($organizationId: String!) {
        userReport(organizationId: $organizationId) {
          ...ReportConfig
        }
      }${this.reportGraphqlFragment}
    `;
    const QUERY_VAR = {organizationId};
    return this.generalService.get(QUERY, QUERY_VAR).pipe(
      map(response => plainToInstance(Report, response.data['userReport']))
    );
  }

  /**
   * Send last report for the organization
   * @param organizationId
   */
  public sendLastReport(organizationId: string): Observable<any> {
    const MUTATION = gql`
      mutation SendLastUserReport($organizationId: String!) {
        sendLastUserReport(organizationId: $organizationId)
      }
    `;
    const MUTATION_VAR = {organizationId};

    return this.generalService.get(MUTATION, MUTATION_VAR);
  }

  public updateUserReport(reportData: any): Observable<any> {
    const COMBINED_MUTATION = gql`
      mutation UpdateUserReport($reportId: Int!, $reportInput: UserReportInput!, $organizationId: String!) {
        updateUserReport(reportId: $reportId, reportInput: $reportInput, organizationId: $organizationId) {
          ...ReportConfig
        }
      }${this.reportGraphqlFragment}
    `;

    return this.generalService.set(COMBINED_MUTATION, reportData);
  }

  /**
   * Create a user report configuration for the current user in the given organization.
   * @param organizationId The ID of the organization.
   * @param timezoneId The ID of the timezone.
   * @return The report.
   */
  public createReport(organizationId: string, timezoneId: string): Observable<Report> {
    const MUTATION = gql`
      mutation CreateUserReport($organizationId: String!, $timezoneId: String!) {
        createUserReport(organizationId: $organizationId, timezoneId: $timezoneId) {
          ...ReportConfig
        }
      }${this.reportGraphqlFragment}
    `;
    const MUTATION_VAR = {organizationId, timezoneId};

    return this.generalService.set(MUTATION, MUTATION_VAR).pipe(
      map(response => plainToInstance(Report, response.data['createUserReport']))
    );
  }
}
