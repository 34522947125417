import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfig } from '@app/core/app.config';
import { DocumentStateEnum } from '@app/core/enums/document/document-state.enum';
import { DocumentTypeEnum } from '@app/core/enums/document/document-type.enum';
import { ValidatorType } from '@app/core/enums/validator-type.enum';
import { Asset } from '@app/core/model/entities/asset/asset';
import { CreateDocumentInput, Document, DocumentToCreateFromFile } from '@app/core/model/entities/document/document';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { DocumentsService } from '@app/features/main/views/organization-documents/documents.service';
import { DateCellEditComponent } from '@app/shared/components/cell-edit/date-cell-edit/date-cell-edit.component';
import {
  MultipleAutocompleteCellEditComponent
} from '@app/shared/components/cell-edit/multiple-autocomplete-cell-edit/multiple-autocomplete-cell-edit.component';
import {
  ChipInput,
  MultipleEntityAutocompleteCellEditComponent
} from '@app/shared/components/cell-edit/multiple-autocomplete-cell-edit/multiple-entity-autocomplete-cell-edit.component';
import { SelectCellEditComponent } from '@app/shared/components/cell-edit/select-cell-edit/select-cell-edit.component';
import {
  TextAreaCellEditComponent
} from '@app/shared/components/cell-edit/text-area-cell-edit/text-area-cell-edit.component';
import { TextCellEditComponent } from '@app/shared/components/cell-edit/text-cell-edit/text-cell-edit.component';
import {
  CustomTextSuffixCellEditorParams
} from '@app/shared/components/cell-edit/text-suffix-cell-edit/text-suffix-cell-edit.component';
import { CreateDocumentData } from '@app/shared/components/file-uploader/file-uploader.component';
import { Dimensions } from '@app/shared/extra/column-dimensions.enum';
import { ColumnType } from '@app/shared/extra/column-type.enum';
import { ColumnBuilder } from '@app/shared/grid/column-builder';
import { GridOptionsService } from '@app/shared/grid/grid-options.service';
import { TranslateService } from '@ngx-translate/core';
import { SectionService } from '@services/section.service';
import { ColumnState, GridApi, GridOptions, GridReadyEvent, ProcessCellForExportParams } from 'ag-grid-community';
import { ColDef, ICellEditorParams, SuppressKeyboardEventParams } from 'ag-grid-enterprise';
import dayjs from 'dayjs';
import { UploaderOptions } from 'ngx-uploader';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './organization-document-create-modal.component.html'
})
export class OrganizationDocumentCreateModalComponent implements OnInit, OnDestroy {

  protected gridApi: GridApi;
  protected gridOptions: GridOptions;
  protected options: UploaderOptions;

  protected addedFiles: DocumentToCreateFromFile[] = [];

  protected isEditing: boolean;

  protected categories: string[];
  protected assets: Asset[];
  protected documentStates = Object.values(DocumentStateEnum);
  protected uploadData: any;

  protected destroy$ = new Subject<void>();
  protected isUploadInProgress = false;
  protected propertiesGridColumnState: ColumnState[];

  private configFetched = new Subject<void>();

  // Injection
  protected gridOptionsService = inject(GridOptionsService);

  protected appConfig = inject(AppConfig);
  protected documentsService = inject(DocumentsService);
  private sectionService = inject(SectionService);
  private columnBuilder = inject(ColumnBuilder);
  private translate = inject(TranslateService);
  private data: { uploadData: any } = inject(MAT_DIALOG_DATA);

  constructor() {
    // Init file upload options
    this.uploadData = {
      ...this.data.uploadData,
      concurrency: 1,
      allowedContentTypes: undefined,
      maxSize: this.appConfig.MAX_FILE_SIZE,
      maxUploads: this.appConfig.MAX_UPLOADS_DOCUMENTS
    };

    // Init Document properties grid
    const extraDocumentGridOptions: GridOptions = {
      suppressMovableColumns: true,
      rowDragManaged: true,
      defaultColDef: {
        resizable: true,
      },
      enableRangeSelection: true,
      suppressCopyRowsToClipboard: true,
      components: {
        textCellEditor: TextCellEditComponent,
        dateCellEditor: DateCellEditComponent,
        textAreaEditor: TextAreaCellEditComponent,
        selectCellEditor: SelectCellEditComponent,
        multipleEntityAutocompleteCellEditor: MultipleEntityAutocompleteCellEditComponent,
        multipleAutocompleteCellEditor: MultipleAutocompleteCellEditComponent
      },
      localeText: this.columnBuilder.localeTextCommon(),
      getMainMenuItems: params => params.defaultItems,
      onGridSizeChanged: () => this.gridApi.sizeColumnsToFit(),
      onCellEditingStarted: () => this.isEditing = true,
      onCellEditingStopped: event => {
        if (event.column.getColId() === 'assets') {
          event.columnApi.autoSizeColumn(event.column.getColId());
        }
        this.isEditing = false;
      },
      processCellFromClipboard: params => this.onPaste(params),
      processCellForClipboard: params => {
        switch (params.column.getColId()) {
          case 'assets' :
            return params.node.data.document.assets.map((asset) => asset.entityId);
          case 'date' :
            return params.value ? dayjs(params.value).format(this.appConfig.DATETIME_FORMAT) : params.node.data.document.date;
          default :
            return params.value;
        }
      },
      getRowId: undefined
    };
    this.gridOptions = {
      ...this.gridOptionsService.staticGridOptions,
      ...extraDocumentGridOptions
    };

    this.propertiesGridColumnState = [{colId: 'defaultSortCol', sort: 'asc'}];
  }

  /**
   * Fetch available Assets and categories.
   */
  public ngOnInit(): void {
    this.fetchUploadConfiguration()
      .subscribe(({assets, categories}) => {
        this.assets = assets;
        this.categories = categories;
        this.configFetched.next();
      });
  }

  /**
   * Stop Observable subscriptions.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Process a file that has been uploaded then add a line in the properties grid.
   * @param documentData Uploaded file information to use for the Document's properties.
   */
  public onFileUploaded(documentData: CreateDocumentData): void {
    this.addedFiles.push(this.buildDocumentFromFile(documentData));
    if (this.gridApi) {
      this.gridApi.setRowData(this.addedFiles);
    }
  }

  /**
   * Whether the file upload is in a valid state, i.e. at least one file has been uploaded and all uploads are completed.
   * @return True if the file upload is done, false otherwise.
   */
  public isUploadInvalid(): boolean {
    return this.isUploadInProgress || this.addedFiles.length === 0;
  }

  /**
   * Update the uploading state with the provided boolean value.
   * @param value Whether one or more file(s) are being uploaded.
   */
  public uploadIsInProgress(value: boolean): void {
    this.isUploadInProgress = value;
  }

  /**
   * Register GridApi, set column definitions, apply column state.
   * @param params Ag-grid event.
   */
  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.createColumnDefs().subscribe(colDefs => {
      this.gridApi.setColumnDefs(colDefs);
      this.gridOptions.columnApi.applyColumnState({state: this.propertiesGridColumnState});
    });
  }

  /**
   * Create a Document input for each row of the properties grid.
   * @return Document inputs.
   */
  public getCreateDocumentsInput(): CreateDocumentInput[] {
    const documentsToUpdate: CreateDocumentInput[] = [];
    if (this.gridApi) {
      this.gridApi.forEachNode(node => {
        const document: CreateDocumentInput = {
          fileName: node.data.fileName,
          documentType: node.data.document.documentType,
          documentState: node.data.document.documentState,
          documentName: (node.data.document.documentName) ? node.data.document.documentName + (node.data.document as Document).getExtension() : node.data.document.name,
          ...(node.data.document.assets && {assetIds: node.data.document.assets.map(entity => entity.entityId)}),
          properties: {
            // Use spread to add optional field if condition is fulfilled
            ...(node.data.document.documentCategory && {documentCategory: node.data.document.documentCategory}),
            ...(node.data.document.date && {documentDate: dayjs(node.data.document.date).format(this.appConfig.DATE_FORMAT)}),
            ...(node.data.document.description && {description: node.data.document.description})
          }
        };
        documentsToUpdate.push(document);
      });
    }
    return documentsToUpdate;
  }

  /**
   * Handle pasting withing a cell of the properties grid.
   * @param params Ag-grid params.
   * @return Transformed pasted value.
   */
  public onPaste(params: ProcessCellForExportParams): any {
    switch (params.column.getColId()) {
      case 'date' :
        return dayjs(params.value).isValid() ? params.value : params.node.data.document.date;
      case 'documentState' :
        const documentState = params.context.documentStates.find((state) => params.context.translate.instant('VALUE.' + state) === params.value);
        const entityId = documentState ? documentState : params.node.data.document['documentState'];
        return {entityId: entityId};
      case 'documentCategory' :
        const categories = params.value.split(',');
        return categories.every(category => category !== void 0) ? categories.map(category => category.trim())
          : params.node.data.document.documentCategory;
      case 'assets' :
        const ids = params.value.split(',');
        const entities = ids.map(id => {
          const asset = this.assets.find(asset => asset.id === id);
          if(asset !== void 0){
            return {entityId: asset?.id, entityName: asset?.toString()};
          }
          return void 0;
        });
        return entities.every(entity => entity !== void 0) ? entities : params.node.data.document.assets;
      case 'assetDocumentSection' :
        const mimeType = params.node.data.document.mimeType.toString();
        if (!mimeType.includes('image')) {
          break;
        } else if (params.value == this.translate.instant('LABEL.DOCUMENT_SECTION_' + DocumentTypeEnum.ASSET_DOCUMENT)) {
          return {entityId: DocumentTypeEnum.ASSET_DOCUMENT};
        } else if (params.value == this.translate.instant('LABEL.DOCUMENT_SECTION_' + DocumentTypeEnum.ASSET_PICTURE)) {
          return {entityId: DocumentTypeEnum.ASSET_PICTURE};
        } else {
          return {entityId: params.node.data.document.documentType};
        }
      default:
        return params.value;
    }
  }

  /**
   * Fetch available Assets and categories for autocompletion in the Document properties grid.
   * @return Observable of the upload configuration.
   * @protected
   */
  protected fetchUploadConfiguration(): Observable<{ assets: Asset[], categories: string[] }> {
    return this.documentsService.getUploadDocumentsConfig()
      .pipe(takeUntil(this.destroy$));
  }

  /**
   * Build an Organization's Document from an uploaded file's data.
   * @param documentData Uploaded file information to use for the Document's properties.
   * @return Data to use for creating a new Document.
   * @protected
   */
  protected buildDocumentFromFile(documentData: CreateDocumentData): DocumentToCreateFromFile {
    const documentToCreateFromFile = new DocumentToCreateFromFile(documentData.fileName, documentData.documentName);
    documentToCreateFromFile.document.mimeType = documentData.mimeType;
    documentToCreateFromFile.document.documentType = DocumentTypeEnum.ORGANIZATION_DOCUMENT;
    documentToCreateFromFile.document.documentState = DocumentStateEnum.ACTIVE;
    return documentToCreateFromFile;
  }

  /**
   * Column definitions for the created Document's properties grid.
   * @returns Column definitions.
   */
  private createColumnDefs(): Observable<ColDef[]> {
    // Retrieve document's fields configuration
    const documentSheetSection = this.sectionService.getSectionsByFormCode('document_sheet');

    // Wait for Assets and categories to have been fetched
    return this.configFetched.pipe(
      switchMap(() => documentSheetSection),
      takeUntil(this.destroy$),
      map((sheetSections) => {
        return sheetSections
          .flatMap(section => section.fieldGroups)
          .flatMap(fieldGroups => fieldGroups.fieldConfigs);
      }),
      map(fieldsConfigs => {
        return [
          {
            colId: 'documentName',
            headerName: this.translate.instant('LABEL.DOCUMENT_NAME') + '*',
            valueGetter: (params): any => {
              return (params.data['document'] as Document).getNameWithoutExtension();
            },
            valueSetter: (params): void => {
              params.data['document']['name'] = params.newValue + (params.data['document'] as Document).getExtension();
            },
            valueFormatter: (params): string => {
              return params.value + (params.data['document'] as Document).getExtension();
            },
            editable: true,
            cellEditor: 'textCellEditor',
            filter: 'agSetColumnFilter',
            filterParams: {
              buttons: ['clear']
            },
            cellClassRules: {
              'error-cell': (params): boolean =>
                params.value === void 0 || (params.value as string).length > this.appConfig.FIELD_MAX_LENGTH
            },
            cellEditorParams: (params: ICellEditorParams): CustomTextSuffixCellEditorParams => {
              return {
                fieldConfig: fieldsConfigs.find(fieldsConfig => fieldsConfig.code === 'name'),
                validators: [
                  new FieldValidator(ValidatorType.MAX_LENGTH, this.appConfig.FIELD_MAX_LENGTH),
                  new FieldValidator(ValidatorType.REQUIRED)
                ],
                value: (params.data['document'] as Document).getNameWithoutExtension(),
                suffix: (params.data['document'] as Document).getExtension()
              };
            },
            menuTabs: ['filterMenuTab'],
            cellClass: ColumnType.TEXT,
            width: Dimensions.LG,
            minWidth: Dimensions.MD,
          },
          {
            colId: 'documentCategory',
            headerName: this.translate.instant('LABEL.DOCUMENTCATEGORY'),
            editable: true,
            field: 'document.documentCategory',
            valueSetter: (params): void => {
              params.data['document']['documentCategory'] = params.newValue;
            },
            valueGetter: (params): string[] => {
              return params.data['document']['documentCategory'] ?? [];
            },
            valueFormatter: (params: { value: string[] }): string => {
              return params.value.length === 0 ? this.appConfig.EMPTY_FIELD_VALUE : params.value.join(', ');
            },
            cellEditor: 'multipleAutocompleteCellEditor',
            filter: 'agSetColumnFilter',
            filterParams: {
              buttons: ['clear']
            },
            cellEditorParams: (params: ICellEditorParams): CustomTextSuffixCellEditorParams => {
              return {
                fieldConfig: fieldsConfigs.find(fieldsConfig => fieldsConfig.code === 'documentCategory'),
                value: params.data['document']['documentCategory'] ? params.data['document']['documentCategory'] : this.appConfig.EMPTY_FIELD_VALUE,
                suggestedOptions: this.categories,
                validators: [new FieldValidator(ValidatorType.MAX_LENGTH, this.appConfig.FIELD_MAX_LENGTH)],
                api: params.api
              };
            },
            suppressKeyboardEvent: (params: SuppressKeyboardEventParams): boolean => {
              return params.editing;
            },
            menuTabs: ['filterMenuTab'],
            cellClass: ColumnType.TEXT,
            width: Dimensions.LG,
            minWidth: Dimensions.SM
          },
          {
            colId: 'assets',
            headerName: this.translate.instant('LABEL.DOCUMENT_ASSET'),
            editable: true,
            valueSetter: (params): void => {
              params.data['document']['assets'] = params.newValue;
              if (params.newValue) {
                params.data['document']['documentType'] = DocumentTypeEnum.ASSET_DOCUMENT;
              } else {
                params.data['document']['documentType'] = DocumentTypeEnum.ORGANIZATION_DOCUMENT;
              }

            },
            valueGetter: (params): ChipInput[] => {
              return params.data['document']['assets'] ?? [] as ChipInput[];
            },
            valueFormatter: (params: { value: ChipInput[] }): string => {
              return params.value.length > 0 && params.value.every(asset => !!asset.entityName)
                ? params.value.map(entity => entity.entityName).join(', ')
                : this.appConfig.EMPTY_FIELD_VALUE;
            },
            cellEditor: 'multipleEntityAutocompleteCellEditor',
            filter: 'agSetColumnFilter',
            filterParams: {
              buttons: ['clear']
            },
            cellEditorParams: (params: ICellEditorParams): CustomTextSuffixCellEditorParams => {
              const asset = params.data['document']['assets'] ? params.data['document']['assets'] : this.appConfig.EMPTY_FIELD_VALUE;
              return {
                value: asset,
                suggestedOptions: [
                  ...this.assets.map(asset => {
                    return {entityId: asset.id, entityName: `${asset.identifier} - ${asset.name}`};
                  }),
                  // TODO should be handled in MatFormField inside the cell editor
                  {entityName: this.appConfig.EMPTY_FIELD_VALUE, entityId: undefined}
                ],
                api: params.api
              };
            },
            wrapText: true,
            menuTabs: ['filterMenuTab'],
            cellClass: ColumnType.TEXT,
            width: Dimensions.LG,
            minWidth: Dimensions.SM
          },
          {
            colId: 'assetDocumentSection',
            headerName: this.translate.instant('LABEL.DISPLAY_DOCUMENT_IN'),
            editable: (params): boolean => {
              return params.data['document']['documentType'] !== DocumentTypeEnum.ORGANIZATION_DOCUMENT
                && params.data['document']['mimeType'].toString().includes('image');
            },
            valueSetter: (params): boolean => {
              params.data['document']['documentType'] = params.newValue;
              return true;
            },
            valueGetter: (params): any => {
              return this.translate.instant('LABEL.DOCUMENT_SECTION_' + params.data['document']['documentType']);
            },
            cellEditor: 'selectCellEditor',
            filter: 'agSetColumnFilter',
            filterParams: {
              buttons: ['clear']
            },
            cellEditorParams: (params: ICellEditorParams): CustomTextSuffixCellEditorParams => {
              const entityList = [
                {
                  entityName: this.translate.instant('LABEL.DOCUMENT_SECTION_' + DocumentTypeEnum.ASSET_DOCUMENT),
                  entityId: DocumentTypeEnum.ASSET_DOCUMENT
                }
              ];
              if (params.data['document']['mimeType'].toString().includes('image')) {
                entityList.push(
                  {
                    entityName: this.translate.instant('LABEL.DOCUMENT_SECTION_' + DocumentTypeEnum.ASSET_PICTURE),
                    entityId: DocumentTypeEnum.ASSET_PICTURE
                  }
                );
              }
              return {
                value: {
                  entityName: this.translate.instant('LABEL.DOCUMENT_SECTION_' + params.data['document']['documentType']),
                  entityId: params.data['document']['documentType']
                },
                entityList: entityList,
                fieldConfig: fieldsConfigs.find(fieldsConfig => fieldsConfig.code === 'documentType'),
              };
            },
            menuTabs: ['filterMenuTab'],
            cellClass: ColumnType.TEXT,
            width: Dimensions.LG,
            minWidth: Dimensions.SM
          },
          {
            colId: 'date',
            headerName: this.translate.instant('LABEL.DOCUMENT_DATE'),
            valueFormatter: (params): string => {
              return (params.value) ? dayjs(params.value).format(this.appConfig.DISPLAY_DATE_FORMAT) : undefined;
            },
            editable: true,
            field: 'document.date',
            cellEditor: 'dateCellEditor',
            filter: 'agSetColumnFilter',
            filterParams: {
              buttons: ['clear']
            },
            cellEditorParams: (params: ICellEditorParams): CustomTextSuffixCellEditorParams => {
              return {
                value: params.data['document']['date'],
                validators: [new FieldValidator(ValidatorType.MAX_NOW)]
              };
            },
            menuTabs: ['filterMenuTab'],
            cellClass: ColumnType.TEXT,
            width: Dimensions.LG,
            minWidth: Dimensions.SM
          },
          {
            colId: 'description',
            headerName: this.translate.instant('LABEL.DOCUMENT_DESCRIPTION'),
            editable: true,
            field: 'document.description',
            cellEditor: 'textAreaEditor',
            filter: 'agSetColumnFilter',
            filterParams: {
              buttons: ['clear']
            },
            cellEditorParams: (params: ICellEditorParams): CustomTextSuffixCellEditorParams => {
              return {
                value: params.data['document']['description']
              };
            },
            menuTabs: ['filterMenuTab'],
            cellClass: ColumnType.TEXT,
            width: Dimensions.LG,
            minWidth: Dimensions.SM
          },
          {
            colId: 'documentState',
            headerName: this.translate.instant('LABEL.DOCUMENTSTATE'),
            editable: true,
            cellEditor: 'selectCellEditor',
            filter: 'agSetColumnFilter',
            filterParams: {
              buttons: ['clear']
            },
            valueSetter: (params): void => {
              params.data['document']['documentState'] = params.newValue;
            },
            valueGetter: (params): any => {
              return this.translate.instant('VALUE.' + params.data['document']['documentState']);
            },
            cellEditorParams: (params: ICellEditorParams): CustomTextSuffixCellEditorParams => {
              return {
                fieldConfig: fieldsConfigs.find(fieldsConfig => fieldsConfig.code === 'documentState'),
                value: {
                  entityId: params.data['document']['documentState'],
                  entityName: this.translate.instant('VALUE.' + params.data['document']['documentState'])
                },
                entityList: this.documentStates.map(key => ({
                  entityId: key,
                  entityName: this.translate.instant('VALUE.' + key)
                }))
              };
            },
            menuTabs: ['filterMenuTab'],
            cellClass: ColumnType.TEXT,
            width: Dimensions.LG,
            minWidth: Dimensions.SM
          }
        ] as ColDef[];
      })
    );
  }
}
